import React from "react";
import Img from "gatsby-image";

export default class RecordingCover extends React.Component {
  render() {
    const { node } = this.props.node;
    console.log("Recovering cover", node);
    return (
      <div>
        <article class="project-recording-link">
          <figure>
            <p
              class="image is-128x128"
              style={{ boxShadow: "0 0 10px rgba(0,0,0,0.2)", border: "solid 1px white", borderRadius: "0px", overflow: "hidden" }}
            >
              <Img
                fixed={node.frontmatter.images[0].childImageSharp.thumbnail}
              />
            </p>
          </figure>

          <div>
            <div>
              <h3 class="heading">{node.frontmatter.type}</h3>
              <p class="title is-6">{node.frontmatter.title}</p>

              <p
                class="buttons"
                style={{ marginTop: "0.75rem" }}
                className="is-centered"
              >
                <a
                  class="button is-light is-outlined is-fullwidth"
                  href={`/products/${node.fields.id}`}
                >
                  Shop now
                </a>
              </p>
            </div>
          </div>
        </article>
      </div>
    );
  }
}
