import React, { Component } from "react";
import "./Repertoire.scss";

export default class Repertoire extends React.Component {

    render() {
        const works = this.props.works;
        if(works) {

            return (
                <div className="is-darkinventions-project-repertoire">
                    <h3>Project repertoire</h3>
                    <ul>
                        {
                            works.map((work) => {
                                return (<li><span class="work-title">{work.title}</span> <span class="work-composer">
                                    {work.composer}</span></li>)
                            })
                        }
                    </ul>
                </div>
            )
        } else {
            return (null)
        }

    }
}
