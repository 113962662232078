import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import RecordingCover from "../components/RecordingCover/RecordingCover";
import Img from "gatsby-image";
import Repertoire from "../components/Repertoire/Repertoire";
import {
  Container,
  Columns,
  Column,
  Content,
  Section,
  Breadcrumb,
  BreadcrumbItem,
  Icon
} from "bloomer";
import rehypeReact from "rehype-react";

export default class ProjectTemplate extends React.Component {
  render() {
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        columns: Columns,
        column: Column,
      },
    }).Compiler;

    const post = this.props.data.post;
    const recordings = this.props.data.recordings
      ? this.props.data.recordings.edges
      : null;

    const template = (
      <Layout>
        <Helmet>
          <title>{`${post.frontmatter.title} | ${config.siteTitle}`}</title>
        </Helmet>

        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/projects">Projects</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>{post.frontmatter.title}</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 class="title is-1" style={{ zIndex: 35 }}>
              {post.frontmatter.title}
            </h1>
            <p class="subtitle is-4">{post.frontmatter.tagline}</p>
            <hr />
            <Columns>
              <Column>
                <Content>
                  <div>{renderAst(post.htmlAst)}</div>
                </Content>
              </Column>

              {post.frontmatter.repertoire ? (
                <Column isSize={{ desktop: 4 }}>
                  {recordings.length ? (
                    <div class="notification is-primary">
                      <div className="recordings">
                        {recordings.map((node) => {
                          return <RecordingCover node={node} />;
                        })}
                      </div>
                    </div>
                  ) : null}

                  <Repertoire works={post.frontmatter.repertoire} />
                </Column>
              ) : null}
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
    return template;
  }
}

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      timeToRead
      excerpt
      frontmatter {
        title
        tagline
        repertoire {
          title
          composer
        }
        cover {
          childImageSharp {
            thumbnail: fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed
            }
            full: fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
            hero: fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
    recordings: allMarkdownRemark(
      filter: {
        fields: { template: { eq: "products" } }
        frontmatter: { relatesTo: { eq: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            id
          }
          frontmatter {
            title
            type
            images {
              childImageSharp {
                thumbnail: fixed(width: 128, height: 128) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            links {
              label
              url
            }
          }
        }
      }
    }
  }
`;
